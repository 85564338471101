import ActivitiesSearchSection from '@/components/activities/search-section';
import Layout from '@/components/layout';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const HomepageAgenda = () => (
  <Layout>
    <ActivitiesSearchSection />
    <section className="section mt-6">
      <div className="container is-max-desktop">
        <div className="columns">
          <div className="column has-text-centered">
            <StaticImage
              src="../assets/images/etre-plus-agenda-des-activites-evenements-seminaires-conferences-logo.webp"
              alt="Etre plus - Agenda des activités, séminaires, conférences, bien-être, développement personnel, bio"
            />
          </div>
          <div className="column has-text-justified">
            <p className="mb-3">
              Vous souhaitez prendre soin de vous ? Partir à la rencontre de
              votre intériorité ? Découvrir des techniques d’épanouissement
              personnel ? Vous y former à titre personnel ou professionnel ?
            </p>
            <p className="mb-3">
              Depuis 25 ans Être Plus (anciennement appelé Agenda Plus) est la
              référence Belge des Médecines douces et alternatives, de la
              Spiritualité, du Mieux-être et de l’Épanouissement personnel (plus
              de 150 pratiques et disciplines proposées chaque année).
            </p>
            <p className="mb-3">
              Chaque mois nous vous proposons l’agenda d’activités le plus
              complet de Belgique : conférences, portes ouvertes, stages,
              ateliers, cours, formations …
            </p>
            <p className="has-text-centered">
              <Link
                className="button is-secondary"
                to="/agenda-des-activites-et-des-conferences"
              >
                <span>Découvrez toutes les activités</span>

                <span className="icon">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section my-6">
      <div className="container is-max-desktop">
        <div className="columns">
          <div className="column has-text-justified">
            <h2 className="title is-4">En Avant-Première !</h2>
            <p className="mb-3">
              Chaque mois découvrez une sélection d’activités et de nouveautés
              proposées par nos partenaires thérapeutes, praticiens,
              conférenciers et enseignants.
            </p>
            <p className="has-text-centered">
              <a
                className="button is-secondary"
                href="https://etreplus.be/agenda"
              >
                <span>Nos activités en avant-première</span>

                <span className="icon">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </a>
            </p>
          </div>
          <div className="column has-text-centered">
            <StaticImage
              style={{ borderRadius: 9999 }}
              src="../assets/images/etre-plus-activites-developpement-personnel-bien-etre-agenda-annuaire.webp"
              alt="Etre plus magazine/annuaire papier et digital"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section mb-6">
      <div className="container is-max-desktop">
        <div className="has-text-centered mb-5">
          <h2 className="title is-3">Les activités organisées par Être Plus</h2>
          <p className="subtitle has-text-secondary is-italic">
            Grandir en humanité
          </p>
        </div>
        <div className="columns is-centered is-variable is-8 has-text-justified is-multiline">
          <div className="column is-6 is-flex is-flex-direction-column is-justify-content-space-between mb-5">
            <div>
              <h2 className="title is-4">Méditons ensemble</h2>
              <p className="mb-5">
                Un jeudi sur deux, de 20h15 à 21h15, différents intervenants
                nous offrent leur temps et précieux enseignements. Ce moment de
                reconnexion à soi et de partage avec une « communauté » nous
                ramène à notre paix intérieure, notre vraie nature.
              </p>
            </div>
            <p className="has-text-centered">
              <a
                className="button is-secondary is-outlined"
                href="https://www.etreplus.be/meditons-ensemble"
              >
                <span>En savoir plus</span>

                <span className="icon">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </a>
            </p>
          </div>
          <div className="column is-6 is-flex is-flex-direction-column is-justify-content-space-between mb-5">
            <div>
              <h2 className="title is-4">Ateliers &amp; Stages</h2>
              <p className="mb-5">
                Être Plus propose tout au long de l’année des ateliers et stages
                permettant d’approfondir notre chemin intérieur et d’élévation
                de niveau de conscience. Les enseignants proposent des approches
                sécularisées, non-duelle, d’inspiration bouddhiste ou encore de
                thérapie de 3ème vague.
              </p>
            </div>
            <p className="has-text-centered">
              <a
                className="button is-secondary is-outlined"
                href="https://www.etreplus.be/cycle-2021-2022"
              >
                <span>En savoir plus</span>

                <span className="icon">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </a>
            </p>
          </div>
          <div className="column is-6 is-flex is-flex-direction-column is-justify-content-space-between mb-5">
            <div>
              <h2 className="title is-4">Colloques &amp; Conférences</h2>
              <p className="mb-5">
                Être Plus propose des journées (colloque) et soirées
                (conférences, ciné-débat …) thématiques tout au long de l’année.
                Leur vocation est d’éclairer notre chemin de vie, de nous ouvrir
                à de nouveaux thèmes et pratiques de spiritualité et
                développement personnel, de nous faire « grandir en humanité » .
              </p>
            </div>
            <p className="has-text-centered">
              <a
                className="button is-secondary is-outlined"
                href="https://www.etreplus.be/colloque"
              >
                <span>En savoir plus</span>

                <span className="icon">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </a>
            </p>
          </div>
          <div className="column is-6 is-flex is-flex-direction-column is-justify-content-space-between mb-5">
            <div>
              <h2 className="title is-4">Salon</h2>
              <p className="mb-5">
                Chaque année Être Plus, ses partenaires thérapeutes et
                praticiens vous donnent rendez-vous tout un week-end, vous
                offrant ainsi la possibilité de rencontrer des thérapeutes
                expérimentés, de découvrir de nouvelles pratiques et thérapies,
                d’écouter des conférenciers experts dans le domaine du
                mieux-être et de la spiritualité, le tout dans un lieu inspirant
                et apaisant.
              </p>
            </div>
            <p className="has-text-centered">
              <a
                className="button is-secondary is-outlined"
                href="https://www.etreplus.be/salon-etre-plus"
              >
                <span>En savoir plus</span>

                <span className="icon">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
export default HomepageAgenda;
